.form_modal__input_container {
  margin-bottom: 1rem;
}

.form_modal__input_container input {
  border: 1px solid rgba(17, 17, 19, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 16px;
  width: 100%;
  transition: 0.2s;
  font-family: Nunito_Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.form_modal__input_container input:focus {
  border: 1px solid #111113;
  outline: 3px solid rgba(17, 17, 19, 0.2);
}

/* PASSWORD */
.password_input_container {
  position: relative;
}

.password_input_container input {
  padding-right: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.password_input_container .eye-icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-45%);
}

/* error */
.form_modal__input_container#error input {
  background: #fff2f4;
  border: 1px solid #e30000;
  outline: none;
  color: #E30000;
}
.form_modal__input_container#error  {
  margin-bottom: 10px;
}

.form_modal__input_container .error_message {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgba(227, 0, 0, 1);
  padding-left: 2px;
  margin-top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
}

.form_modal__input_container .error_message img {
  margin-right: 4px;
}

.password_input_container .eye-icon {
  cursor: pointer;
}
