.RightSidebarMainContainer {
    width: 350px;
    height: 90vh;
    background-color: white;
}

.RightSidebarElementProperties {
    margin: 1.5rem 1rem 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ElementDelete {
    background-color: #DB615C;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
}

.RightSidebarContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 68vh;
    overflow: hidden auto;
    padding: 0rem 1rem;
}

.EditorRowContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 8px;
    font-size: 15px;
}

.EditorRowContainer p {
    width: 5rem;
    font-size: 14px;
}

.EditorRowButton {
    border-radius: 5px;
    background-color: #3184c933;
    padding: 8px 1rem;
    cursor: pointer;
    width: 8rem;
    text-align: center;
}

.EditorRowButton:hover {
    background-color: #0000003d;
}

.StyleEditorContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.color-select {
    width: 26px;
    height: 26px;
    outline: none;
    border: 0;
    border-radius: 5px;
    background-color: aqua;
    box-shadow: 0px 0px 2px 0px;
    position: relative;
}

.color-picker {
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: #dadada;
    top: calc(100% + 10px);
    z-index: 10;
}

.font-styles-editor-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
}

.font-styles-editor-icon#active {
    background-color: #e1e4e7;
}

.layerIcon:hover {
    background-color: #e1e4e7;
}

.text_editor_dropdown {
    border: 1px solid #11111333;
    box-sizing: border-box;
    outline: none;
    padding: 6px 8px;
    width: 215px;
    border-radius: 6px;
    cursor: pointer;
    height: 30px;
}

.ApplyAllBgBtn {
    width: 19.5rem;
    margin: 1rem auto;
    padding: 10px 1rem;
    border-radius: 5px;
    background-color: black;
    color: white;
    cursor: pointer;
    border: 1px solid black;
}

.ApplyAllBgBtn:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
}

/* ////////////////////////////////// */
.text-editor-modal {
    position: absolute;
    top: 2.8rem;
    width: auto;
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 1px 0.25rem 0 #000f3333;
    align-items: center;
    display: flex;
    flex-flow: row;
    justify-content: center;
    left: -1.5rem;
}

.text-editor-modal-button {
    height: 2rem;
    width: 2rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.text-editor-modal-input {
    border: none;
    outline: none;
    text-align: center;
    color: rgb(105, 107, 116);
    padding: 0px;
    transition: color 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0s;
    width: 2rem;
}

.text-editor-button-hover:hover {
    transform: scale(1.04);
}

/* ////////////////////////////////// */
.styles_innerInput__ekrSk {
    background-color: #eff3f8;
    border: 2px solid gray;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 34% 32% 34%;
    grid-template-rows: auto auto auto;
    height: 90px;
    justify-items: center;
    overflow: hidden;
    width: 138px;
    z-index: 1;
}

.styles_innerBackdrop__MyVPh {
    background-color: #fff;
    -webkit-clip-path: polygon(-1px -1px, -1px 100%, 100% -1px, 100% 100%);
    clip-path: polygon(-1px -1px, -1px 100%, 100% -1px, 100% 100%);
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    width: 100%;
}

.styles_innerInput__ekrSk .styles_centerpiece__VYEvl {
    background: #fff;
    border: 1px solid gray;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    width: 100%;
    z-index: 1;
}

.styles_innerInput__ekrSk input {
    background-color: transparent;
    border: none;
    outline: none;
    text-align: center;
    width: 40px;
    z-index: 1;
}

/* /////// */
.addoptionbtn {
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    margin: 1rem auto;
    cursor: pointer;
}

.addoptionbtn:hover {
    background-color: #3184c933;
}

/* ///////////////////timer */

.timer_container {
    background: rgba(243, 243, 238, 0.42);
    border-radius: 0.5rem;
    padding: 4px 4px 4px 5.32px;
    border: 1px solid rgba(243, 243, 238, 1);
}